import React from 'react';
import '../shared/style.css'

const Services = () => {
  return (
    <div>
      <table width="1000" border="0" cellPadding="0">
      <tbody>
        <tr>
          <td width="800" valign="top">
            <div style={{ margin: '30px' }}>
              {/* Content */}
              <h1 className="page_title">Services</h1>
              <img id="FAQFOREST" src="images/forest_sm.jpg" className="framed-artwork" align="right" hspace="5" alt="Forest" width="250" height="450" />

              <h1 className="page_subtitle">Psychological Services</h1>
              <p>
                If you have any reason to undergo a psychological evaluation, I would be happy to be of assistance. I have significant experience doing psychological assessments in hospital, college, and university settings. In addition, I have been conducting medical legal assessments in my private practice. Registered with the College of Psychologists of Ontario and Saskatchewan, I am qualified to diagnose and communicate diagnoses.
              </p>
              <p>
                Going through different stages in our lives: attending college, university, changes at work, getting married, moving, starting a family, illness, losing loved ones, feeling suicidal, aging, lack of appetite, can all be very difficult. Coping with all of these changes can be challenging. The key for coping includes your lifestyle, ability to understand yourself, adapt to change, plan, change, and willingness to stay interested in and involved with life no matter what is in front of you. The future is uncertain, and this is what scares us the most.
              </p>
              <p>
                Fear of the unknown is universal and understandable. Change is a continuous process, part of our lives, and inevitable. Therefore, please do not hesitate to call me, and we can start from there.
              </p>
              <p>
                Clients are required to call and book appointments. Cancellation or rescheduling of appointments requires 24-hour notice. You can communicate with me via phone or email. Messages are checked regularly throughout the week. Calls and emails are returned within 24 hours. During the weekend, calls and emails will be returned as soon as possible.
              </p>
              <p>
                Psychological services are covered by most Extended Health Insurance Plans. Additionally, they can be claimed as part of your medical expenses for tax return purposes.
              </p>

              <h1 className="page_subtitle">Psychological Assessments</h1>

              <h1 className="page_subtitle">
                <strong>
                  <em> (1) Autism Spectrum Disorder and Attention Deficit Hyperactivity Disorder Assessment for Adults</em>
                </strong>
              </h1>
              <p> A psychological evaluation to diagnose of ASD and ADHD in adults involves reviewing personal history and conducting tests to identify symptoms like focus difficulties and impulsivity. This assessment aids in effective treatment planning for better daily functioning. For this assessment corroborating information from an informant (i.e., developmental history) is necessary to make a proper diagnosis. Assessment is conducted over 2 to 3 appointments. At least one appointment has to be done in person, (i.e., conducting ADOS 2 an assessment). </p>

              <h1 className="page_subtitle">
                <strong>
                  <em> (2) Psychological Assessment</em>
                </strong>
              </h1>
              <p>
                For the employee returning to work after a work-related injury, suffering from different mental health issues, chronic pain, and fatigue.
              </p>

              <h1 className="page_subtitle">
                <strong> (3) Motor Vehicle Assessments</strong>
              </h1>
              <p>
                This assessment is conducted after someone has been involved in a motor vehicle accident. The purpose of the psychological assessment is to determine the existence, nature, and degree of psychological dysfunction that resulted directly or indirectly from your motor vehicle accident. Based on the clinical interview and results of the psychometric measures, a treatment plan would be proposed if appropriate.
              </p>

              <h1 className="page_subtitle"> (4) Psychotherapy</h1>
              <ul>
                <li>Consultation</li>
                <li>Cognitive Behavioural Therapy </li>
                <li>Acceptance and Commitment</li>
                <li>Mindfulness Meditation</li>
              </ul>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Services;
