import React, { useState } from 'react';
import '../shared/style.css';

const Problems = () => {
  const [openSections, setOpenSections] = useState(['Presenting Problems', 'Anxiety Disorders']);

  const toggleSection = (sectionTitle) => {
    if (openSections.includes(sectionTitle)) {
      setOpenSections(openSections.filter((section) => section !== sectionTitle));
    } else {
      setOpenSections([...openSections, sectionTitle]);
    }
  };

  return (
    <div>
      <table width="1000" border="0" cellPadding="0">
        <tbody>
          <tr>
            <td width="800" valign="top">
              <div style={{ margin: '30px' }}>
                {/* Content */}
                <h1 id="pointer-supported" className="page_title collapsible" onClick={() => toggleSection('Presenting Problems')}>
                  {openSections.includes('Presenting Problems') ? '- Presenting Problems' : '+ Presenting Problems'}
                </h1>
                {openSections.includes('Presenting Problems') && (
                  <>
                    <img id="FAQFOREST" src="images/tree_1.jpg" className="framed-artwork" align="right" hspace="5" alt="anxiety" />
                    <ul>
                      <li>Anxiety disorders: generalized anxiety, obsessive-compulsive, panic, phobias, post-traumatic stress</li>
                      <li>Depression</li>
                      <li>Adjustment difficulties</li>
                      <li>Self-esteem</li>
                      <li>Pain management</li>
                      <li>Stress management</li>
                      <li>Post motor vehicle accident adjustments</li>
                      <li>Work difficulties</li>
                    </ul>
                  </>
                )}

                <h1 id="pointer-supported" className="page_title collapsible" onClick={() => toggleSection('Anxiety Disorders')}>
                  {openSections.includes('Anxiety Disorders') ? '- Anxiety Disorders' : '+ Anxiety Disorders'}
                </h1>
                {openSections.includes('Anxiety Disorders') && (
                  <>
                    <p>
                      It's normal to worry and feel tense or scared when under pressure or facing a stressful situation. Anxiety is the body's natural response to danger, an automatic alarm that goes off when we feel threatened.
                    </p>
                    <p>
                      Although it may be unpleasant, anxiety isn't always a bad thing. In fact, anxiety can help us stay alert and focused, spur us to action, and motivate us to solve problems. But when anxiety is constant or overwhelming, when it interferes with your relationships and activities—that's when you've crossed the line from normal anxiety into the territory of anxiety disorders.
                    </p>
                    <p>
                      In addition to the primary symptoms of irrational and excessive fear and worry, other common emotional symptoms of anxiety include:
                    </p>
                    <ul>
                      <li>Feelings of apprehension or dread</li>
                      <li>Trouble concentrating</li>
                      <li>Feeling tense and jumpy</li>
                      <li>Anticipating the worst</li>
                      <li>Irritability</li>
                      <li>Restlessness</li>
                      <li>Watching for signs of danger</li>
                      <li>Feeling like your mind's gone blank</li>
                    </ul>
                    <img id="FAQFOREST" src="images/anxiety.avif" className="framed-artwork" width="250" height="275" align="right" alt="anxiety" />
                    Common physical symptoms of anxiety include:
                    <ul>
                      <li>Pounding heart</li>
                      <li>Sweating</li>
                      <li>Stomach upset or dizziness</li>
                      <li>Frequent urination or diarrhea</li>
                      <li>Shortness of breath</li>
                      <li>Tremors and twitches</li>
                      <li>Muscle tension</li>
                      <li>Headaches</li>
                      <li>Fatigue</li>
                      <li>Insomnia</li>
                    </ul>
                  </>
                )}

                <h1 id="pointer-supported" className="page_title collapsible" onClick={() => toggleSection('Types of Anxiety Disorders')}>
                  {openSections.includes('Types of Anxiety Disorders') ? '- Types of Anxiety Disorders' : '+ Types of Anxiety Disorders'}
                </h1>
                {openSections.includes('Types of Anxiety Disorders') && (
                  <>
                    <p>
                      There are six major types of anxiety disorders, each with their own distinct symptom profile: generalized anxiety disorder, obsessive-compulsive disorder, panic disorder, phobia, post-traumatic stress disorder, and social anxiety disorder.
                    </p>

                    <h1 id="pointer-supported" className="page_subtitle collapsible" onClick={() => toggleSection('Generalized Anxiety Disorder')}>
                      {openSections.includes('Generalized Anxiety Disorder') ? '- Generalized Anxiety Disorder' : '+ Generalized Anxiety Disorder'}
                    </h1>
                    {openSections.includes('Generalized Anxiety Disorder') && (
                      <p>
                        If constant worries and fears distract you from your day-to-day activities or you're troubled by a persistent feeling that something bad is going to happen, you may be suffering from generalized anxiety disorder.
                      </p>
                    )}

                    <h1 id="pointer-supported" className="page_subtitle collapsible" onClick={() => toggleSection('Obsessive-Compulsive Disorder')}>
                      {openSections.includes('Obsessive-Compulsive Disorder') ? '- Obsessive-Compulsive Disorder' : '+ Obsessive-Compulsive Disorder'}
                    </h1>
                    {openSections.includes('Obsessive-Compulsive Disorder') && (
                      <p>
                        Obsessive-compulsive disorder is characterized by unwanted thoughts or behaviors that seem impossible to stop or control.
                      </p>
                    )}

                    <h1 id="pointer-supported" className="page_subtitle collapsible" onClick={() => toggleSection('Panic Disorder')}>
                      {openSections.includes('Panic Disorder') ? '- Panic Disorder' : '+ Panic Disorder'}
                    </h1>
                    {openSections.includes('Panic Disorder') && (
                      <>
                        <p>
                          Panic disorder is characterized by repeated, unexpected panic attacks, as well as fear of experiencing another episode. Panic disorder may also be accompanied by agoraphobia, which is a fear of being in places where escape or help would be difficult in the event of a panic attack. If you have agoraphobia, you are likely to avoid public places such as shopping malls or confined spaces such as an airplane.
                        </p>
                        <p>
                          Panic attacks are episodes of intense panic or fear. Panic attacks usually occur suddenly and without warning. Sometimes there's an obvious trigger—getting stuck in an elevator, for example, or thinking about the big speech you're giving in a few hours—but in other cases, the attacks come out of the blue.
                        </p>
                        <p>
                          Panic attacks usually peak within ten minutes, and they rarely last more than a half hour. But during that short time, the terror can be so severe that you feel as if you're about to die or totally lose control. The physical symptoms are themselves so frightening that many people believe they're having a heart attack. After a panic attack is over, you may be worried about having another one, particularly in a public place where help isn't available or you can't easily escape.
                        </p>
                        <p>
                          Symptoms of a panic attack include:
                        </p>
                        <ul>
                          <li>Surge of overwhelming panic</li>
                          <li>Feeling of losing control or going crazy</li>
                          <li>Heart palpitations or chest pain</li>
                          <li>Feeling like you're going to pass out</li>
                          <li>Trouble breathing or choking sensation</li>
                          <li>Hyperventilation</li>
                          <li>Hot flashes or chills</li>
                          <li>Trembling or shaking</li>
                          <li>Nausea or stomach cramps</li>
                          <li>Feeling detached or unreal</li>
                        </ul>
                      </>
                    )}

                    <h1 id="pointer-supported" className="page_subtitle collapsible" onClick={() => toggleSection('Post-Traumatic Stress Disorder')}>
                      {openSections.includes('Post-Traumatic Stress Disorder') ? '- Post-Traumatic Stress Disorder' : '+ Post-Traumatic Stress Disorder'}
                    </h1>
                    {openSections.includes('Post-Traumatic Stress Disorder') && (
                      <p>
                        Post-traumatic stress disorder is an anxiety disorder that can occur in the aftermath of a traumatic or life-threatening event. Symptoms of PTSD include flashbacks or nightmares about what happened, hypervigilance, startling easily, withdrawing from others, and avoiding situations that remind you of the event.
                      </p>
                    )}

                    <h1 id="pointer-supported" className="page_subtitle collapsible" onClick={() => toggleSection('Social Anxiety Disorder')}>
                      {openSections.includes('Social Anxiety Disorder') ? '- Social Anxiety Disorder' : '+ Social Anxiety Disorder'}
                    </h1>
                    {openSections.includes('Social Anxiety Disorder') && (
                      <p>
                        If you have a debilitating fear of being seen negatively by others and humiliated in public, you may have social anxiety disorder, also known as social phobia. Social anxiety disorder can be thought of as extreme shyness. In severe cases, social situations are avoided altogether.
                      </p>
                    )}
                  </>
                )}
                {/* Content end */}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Problems;