import React from 'react';

const Error404 = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    textAlign: 'center',
    justifyContent: 'center', // Add this to center content vertically
    alignItems: 'center',     // Add this to center content horizontally
    width: '50%',             // Set width to 50% of the viewport
    marginLeft: '25%',        // Set margin left to 25% to center the div
  };

  return (
    <div style={containerStyle}>
      <h1>In a Moment of Reflection: This Page is Contemplating its Existence.</h1>
      <p>
        Just like the mind, sometimes links and pages wander off. 
        But don't worry, it's all part of the journey. <br/><br/>
        You can gently redirect yourself back to my <a href="/">homepage</a> or 
        explore other areas of my site.

        Remember, every journey, even digital ones, can have unexpected turns. 
        It's all part of finding your way.
      </p>
    </div>
  );
}

export default Error404;
