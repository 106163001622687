import { useState } from 'react';
import React, { useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";
import styled from "styled-components";
import '../shared/style.css';
import { useLocation } from 'react-router-dom';

const Nav = styled.nav`
  position: fixed; 
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  padding: 5px 0;
  z-index: 10000;
  border-bottom: 2px solid #2c7a3a;
  height: 40px;

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 10px;
  }
`;

const LogoImage = styled.img`
  max-width: 50px;
  max-height: 50px;
  margin: 0;
  vertical-align: middle;
`;

const Hamburger = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }

  &:after {
    content: '\\2630';
    font-size: 24px;
  }
`;

const StyledUl = styled.ul`
  display: flex;
  justify-content: space-around;
  list-style-type: none;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    width: 100%;
    top: 40px;
    transform: translateX(-120%);
    background-color: #ffffff;
    transition: transform 0.2s ease-in-out;

    ${props => props.$show && `
      transform: translateX(-10%);
    `}
  }
`;

const StyledLi = styled.li`
  margin-right: 20px;

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  font-size: 12pt;
  color: #000000;
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    bottom: -5px;
    left: 0;
    background-color: #0c9418;
    visibility: hidden;
    transition: width 0.3s ease-in-out;
  }

  &:hover::after {
    visibility: visible;
    width: 100%;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  cursor: pointer;
`;

const LogoText = styled.div`
  margin-left: 10px;
  color: #000000;
  font-weight: bold;
`;

const MainContent = styled.div`
  margin-top: 40px; 

  @media (max-width: 768px) {
    margin-top: 60px; 
  }

`;

const Layout = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation(); // Get the current location

  const scrollToTopSmooth = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    // Instantly jump to the top of the page when the location changes
    window.scrollTo(0, 0);
  }, [location]); // Dependency array with location

  return (
    <>
      <Nav>
        <LogoContainer onClick={scrollToTopSmooth}>
          <LogoImage alt="Logo" src="images/logo.png" />
          <LogoText>WantChange.ca</LogoText>
        </LogoContainer>
        <Hamburger onClick={() => setShowMenu(!showMenu)} />
        <StyledUl $show={showMenu}>
          <StyledLi>
            <StyledLink to="/" onClick={() => setShowMenu(false)}>Home</StyledLink>
          </StyledLi>
          <StyledLi>
            <StyledLink to="/services" onClick={() => setShowMenu(false)}>Services</StyledLink>
          </StyledLi>
          <StyledLi>
            <StyledLink to="/presenting-problems" onClick={() => setShowMenu(false)}>Presenting Problems</StyledLink>
          </StyledLi>
          <StyledLi>
            <StyledLink to="/faq" onClick={() => setShowMenu(false)}>FAQ</StyledLink>
          </StyledLi>
          <StyledLi>
            <StyledLink to="/payments" onClick={() => setShowMenu(false)}>Payments</StyledLink>
          </StyledLi>
          <StyledLi>
            <StyledLink to="/contact" onClick={() => setShowMenu(false)}>Contact</StyledLink>
          </StyledLi>
        </StyledUl>
      </Nav>
      <MainContent>
        <Outlet />
      </MainContent>
    </>
  );
};

export default Layout;