import React from 'react';
import '../shared/style.css'


const Payments = () => {
  return (
    <div>
      <table width="1000" border="0" cellPadding="0">
      <tbody>
        <tr>
          <td width="800" valign="top">
            <div style={{ margin: '30px' }}>
              {/* Content */}
              <h1 className="page_title">Payment methods</h1>
              <img src="images/interac_logo.gif" align="right" hspace="5" alt="Interac Logo" />
                <h1 className="page_subtitle">Interac Email Money Transfer</h1>
                <p>
                  Interac Email Money Transfers are a simple, convenient, and secure way to send and receive money directly from one bank account to another. All you need is access to online banking through a participating financial institution, and you can send money to anyone with an email address and a bank account in Canada - without sharing any personal or financial information.
                </p>
                <p>
                  For more information about Interac Email Money Transfers and to see a demo, visit <a href="https://www.interac.ca/en/consumers/products/interac-e-transfer/">Interac website</a>.
                </p>
                <p>
                  Your payment should be sent to this email address: <b>andjelkapa@gmail.com</b>.
                </p>
              <h1 className="page_subtitle">Coverage</h1>
              <p>
                Psychotherapy sessions with a Registered Psychologist and/or Psychological Associate are partially tax deductible. They can be claimed under health expenses when you file your income tax return.
              </p>
              <p>
                Insurance Coverage: Registered Psychologist and Psychological Associates fees are often covered (at least in part) by your extended health care plan. You should consult with your extended health care provider.
              </p>
              <p>
                Victims of motor vehicle accidents may qualify for services with a Registered Psychologist or Psychological Associate with coverage from their auto insurance.
              </p>
              <p>
                Individuals with WSIB claims are also able to have their Psychological fees covered.
              </p>
              <p>
                Psychological services are not covered by OHIP.
              </p>
              {/* Content end */}
            </div>
          </td>
        </tr>
       </tbody>
      </table>
    </div>
  );
}

export default Payments;
