import React from 'react';
import '../shared/style.css';

const FAQ = () => {
  return (
    <div className="faq-container" style={{ maxWidth: '1000px', margin: '75px auto 0'}}>
      <div className="faq-content" style={{ margin: '30px' }}>
        {/* Content */}
        <h1 className="page_title">Frequently asked questions</h1>
        <img id="FAQFOREST" src="images/brook.jpg" className="framed-artwork" align="right" hspace="5" alt="Forest" />
        <div className="faq-section" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <div className="faq-text" style={{ flex: '1' }}>
            <h1 className="page_subtitle">What is Psychotherapy?</h1>
            <p>
              Psychotherapy is a process in which through open and honest communication with a professional one can come to gain a deeper understanding of oneself, reduce emotional distress (such as depression, anxiety), feel more in control of one's life, build healthy ways of coping with difficulties and stressors in life, and develop communication skills that might help in developing a more satisfying and meaningful life.
            </p>
            <p>
              Psychotherapy has its own advantages and disadvantages. One should be aware that not everyone benefits from psychotherapy.
            </p>
            <p>
              I have been practicing mainly from a Cognitive Behavioural Model; however, a collaborative approach, tailored for each client in order to best suit their individual needs is used.
            </p>
          </div>
        </div>

        <h1 className="page_subtitle">Medication</h1>
        <p>
          
        As a registered Psychological Associate, M.A., Clinical Psychologist, I do not and cannot prescribe medication. I do respect one's choice to use or not to use medication. I will be happy to work collaboratively with your family physician or psychiatrist in this regard.
        </p>

        <h1 className="page_subtitle">How long does psychotherapy take?</h1>
        <p>
          Psychotherapy is open and honest communication with a professional in regularly scheduled sessions on a weekly or bi-weekly basis. Depending on the presenting issues or problems, we collaboratively determine the number of sessions. If a client is displaying a behavioral problem, the goals could be reached in about 8-12-20 sessions. This is short-term psychotherapy, usually involving learning and practicing new skills. Long-term therapy is mostly related to gaining a better understanding and awareness of one's relationship patterns, thoughts, and feelings and improving the quality of life in general.
        </p>

        <h1 className="page_subtitle">Coverage</h1>
        <p>
          Psychotherapy sessions with a Registered Psychologist and/or Psychological Associate are partially tax-deductible. They can be claimed under health expenses when you file your income tax return.
        </p>
        <p>
          Insurance Coverage: Registered Psychologist and Psychological Associates fees are often covered (at least in part) by your extended health care plan. You should consult with your extended health care provider.
        </p>
        <p>
          Victims of motor vehicle accidents may qualify for services with a Registered Psychologist or Psychological Associate with coverage from their auto insurance.
        </p>
        <p>
          Individuals with WSIB claims are also able to have their Psychological fees covered.
        </p>
        <p>
          Psychological services are not covered by OHIP.
        </p>

        <h1 className="page_subtitle">Confidentiality and Privacy Rules for Psychological Services</h1>
        <p>
          Full Information with regards to Confidentiality and privacy rules when providing psychological services can be found at <a href="http://www.mgs.gov.on.ca/en/infoaccessandprivacy/STDU_101458.html">http://www.mgs.gov.on.ca/en/infoaccessandprivacy/STDU_101458.html</a>
        </p>
        <p>
          In a section of the intake form, I will collect personal information. Personal information refers to an individual's personal characteristics such as name, date of birth, home address, and telephone number, their health for example: presenting problem, health history, health services received by the individual, social situation, or their activities and views. Personal information is not the same as business information (e.g., an individual's business address and telephone number), which is not protected by privacy legislation.
        </p>
        <p>
          Various government agencies (e.g., Canada Customs and Revenue Agency, Information and Privacy Commissioner, Human Rights Commission, etc.) have the authority to review my files as part of their mandates. In these circumstances, I may consult with professionals (e.g., lawyers, accountants) who will investigate the matter and report back to me. I will advise you in the event that this occurs.
        </p>
        <p>
          Psychologists take privacy and confidentiality seriously. All the information is confidential and will not be shared with anyone without written consent unless:
        </p>
        <ol>
          <li>You tell me or if I believe from what you are telling me that you are in danger to yourself and to others</li>
          <li>If there is a suspicion of a child being at risk or in need of protection</li>
          <li>If you tell me that you have been sexually assaulted by the regulated health professional</li>
          <li>If my notes have been summoned by the court</li>
        </ol>
        <p>
          If your psychological services are paid by third parties such as a motor vehicle insurance, EAP Companies, WSIB, then usually these organizations have your consent for disclosing certain information pertinent to the psychological treatment in order to display client rights to this funding.
        </p>
      </div>
    </div>
  );
}

export default FAQ;
