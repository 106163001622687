import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FAQ from "./Components/FAQ";
import Problems from "./Components/Problems";
import Services from "./Components/Services";
import Contact from "./Components/Contact";
import Payments from "./Components/Payments";
import Home from "./Components/Home";
import Layout from "./Components/Layout";
import Error404 from "./Components/Error404";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<><Layout /> </>}>
          <Route index element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="payments" element={<Payments />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="contact" element={<Contact />} />
          <Route path="presenting-problems" element={<Problems />} />
          <Route path="*" element={<Error404  />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);