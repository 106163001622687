import React from 'react';
import '../shared/style.css'

const Location = () => {
  return (
    <div>
      <table width="1000" border="0" cellPadding="0">
      <tbody>
        <tr>
          <td width="800" valign="top">
            <div style={{ margin: '30px' }}>
              {/* Content */}
              <h1 className="page_title">Contact Me</h1>
              
              <table width="100%">
              <tbody>
                <tr>
                  <td valign="top">
                    <p>
                      Ready to take the next step towards wellness and balance? <br/><br/> Contact me today to book your appointment! Reach out via phone or email to discuss how I can support you on your journey. Whether you're seeking guidance, a psychological assessment, or just need someone to talk to, I'm here to help.<br/><br/> I look forward to hearing from you and starting this transformative journey together
                    </p>
                    <p>
                      <span className="contact">Mobile: +1 416-320-8376</span>
                      <br />
                      Email: <a href="mailto:andjelkapa@gmail.com">andjelkapa@gmail.com</a>
                    </p>

                  </td>
                  <td>
                    <img id="FAQFOREST" src="images/bri2.jpg" className="framed-artwork" align="right" hspace="5" alt="Tree" width="400" height="300" />
                  </td>
                </tr>
               </tbody>
              </table>
              <b>Location</b>
                    <br />Bloor and Yonge,
                    <br />Toronto, ON.
              <div style={{ width: '100%' }}>
                <iframe
                  title="Embedded Google Map"
                  width="100%"
                  height="400"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Bloor%20and%20yonge+(Palikucin-Reljin%20Psychology)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.maps.ie/population/">Population mapping</a>
                </iframe>
              </div>

              {/* Content end */}
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Location;
