import React, { useEffect, useState, useRef } from 'react';
import '../shared/style.css';

const Home = () => {
    const [quote, setQuote] = useState('');
    const mainContentRef = useRef(null);
    const [showTherapyServices, setShowTherapyServices] = useState(false);
    const [showSpecialties, setShowSpecialties] = useState(false);

    useEffect(() => {
        const quotes = [
            "Love me when I less deserve it because it is then when I need it most (Chinese Proverb)",
            "Never mistake knowledge for wisdom. One helps you make mistakes and living; the other helps you make a life. (Sandra Carey)",
            "Education is learning what you did not even know you did not know (Daniel J. Boorstine)",
            "Life is too important to be taken seriously (Oskar Wilde)",
            "The greatest gifts you can give your children are the roots of responsibility and the wings of independence. (Denis Waitley)",
            "Correction does much, but encouragement does more (Goethe)",
            "Never walk on the travel path because it only leads you where the others have been. (Graham Bell)",
            "Many people lose the small joys, in hope for the big happiness. (Pearl S. Buck)",
            "Life can be understood only looking behind, but can be lived only looking ahead. (Soren Kierkegaard)",
            "Why repeat the old errors, if there are so many new errors to commit. (Bertrand Rusel)",
            "Nothing can be taught to a man, but it is possible to help him find the answer within himself. (Galileo Galilei)",
            "Never look down on someone unless it is because you are giving them a hand up.",
            "The highway of life is full of flat squirrels that could not make up their minds.",
            "Be kinder than necessary. Everyone you meet is fighting some kind of Battle.",
            "Watch your thoughts; they become words. Watch your words; they become actions. Watch your actions; they become habits. Watch your habits; they become character. Watch your character; it becomes your destiny.",
            "The nicest thing about the future is that it always starts tomorrow.",
            "Money will buy a fine dog, but only kindness will make him wag his tail."
        ];

        const randomIndex = Math.floor(Math.random() * quotes.length);
        setQuote(quotes[randomIndex]);
    }, []);

    const scrollToMainContent = () => {
        mainContentRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const toggleTherapyServices = () => {
        setShowTherapyServices(!showTherapyServices);
    };

    const toggleSpecialties = () => {
        setShowSpecialties(!showSpecialties);
    };

    return (
        <div className="background-image">
            <div className="landing-content">
                <h1 className="home_title">Andjelka Palikucin, M.A.<br />Clinical Psychologist</h1>
                <img alt="selfie" src="images/Andjelka_3.png" className="profile-image" />
                <h1 className="home_subtitle">Quote of the day</h1>
                <p className="quote" id="quote">{quote}</p>
                <br></br>
                <button onClick={scrollToMainContent} className="scroll-button">↓</button>
            </div>
            <div ref={mainContentRef} className="main-content">
                <img src="images/sf-1.jpg" width="225px" height = "300px" className="framed-artwork" align="right" hspace="20" alt="Andjelka" />
                <h1 className="page_title">About me </h1>
                <p>
                    My name is Andjelka Palikucin, and I am registered with the College of Psychologists of Ontario since 2007,
                    with competence in the area of Clinical Psychology. Over the years, I have gained clinical experience while working in hospital,
                    university, and college clinic settings, as well as my own private practice, which served to strengthen both
                    my assessment and psychotherapy skills. My work experience includes psychiatric, psychological, psycho-educational assessments,
                    and psychotherapeutic treatment of adults with different mental health issues such as mood and anxiety disorders,
                    personality disorders, and psychotic disorders. Since 2015 my specialty has been diagnostic clarification and treatment for people on the autism spectrum.
                </p>
                <p>
                    I completed the Advanced training program in Cognitive Behavioural Therapy at The Hincks-Dellecrest Center in 2007.
                    My work is primarily based on using CBT, as well as Mindfulness mediation specifically modified for neurodivers population,
                    Acceptance and Commitment Therapy (ACT), as well as Eye Movement Repetitive Desensitization (EMDR).
                </p>
                <h1 className="page_subtitle">
                    <button onClick={toggleSpecialties} className="collapsible-button">
                        {showSpecialties ? '- Specialties' : '+ Specialties'}
                    </button>
                </h1>
                {showSpecialties && (
                    <p>
                        I have developed treatment plans and provided therapeutic interventions for individuals with various DSM- diagnoses including
                        Anxiety Disorders (e.g., Post-Traumatic Stress Disorder, Adjustment Disorder, Panic Attack Disorder, Specific Phobias, and General Anxiety Disorder),
                        Mood Disorders (e.g., Depression), Somatic Disorders (e.g., Pain Disorders), Autism Spectrum Disorder, Attention Deficit Hyperactivity Disorder (ADHD)
                        as well as Eating Disorder, for the population over the age of 18.
                    </p>
                )}

                <h1 className="page_subtitle">
                    <button onClick={toggleTherapyServices} className="collapsible-button">
                        {showTherapyServices ? '- Therapy Services' : '+ Therapy Services'}
                    </button>
                </h1>
                {showTherapyServices && (
                    <>
                        <p>
                            <b>Are you getting the emotional support that you require?</b><br />
                            <b>Are you taking care of your body?</b><br />
                            <b> Are you overloaded with responsibilities?</b><br />
                            <b> Do you ask for help when you require it?</b><br /><br />
                            Asking questions like these throughout your day is natural and expected.<br />

                            If you require to talk to someone about anything that has been causing physical discomfort or uncomfortable feelings,
                            please do not hesitate to contact me.
                            I have worked for many years with people experiencing various psychological issues.
                        </p>
                    </>
                )}

                <h1 className="page_subtitle">Contact</h1>
                <p>
                    <span className="contact">Mobile: +1 416-320-8376</span>
                    <br />
                    email: <a href="mailto:andjelkapa@gmail.com">andjelkapa@gmail.com</a>
                </p>
            </div>
        </div>
    );
}

export default Home;